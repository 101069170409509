import React, {useState} from 'react';
import 'scss/Contact.scss';
import { Container, Row, Col } from 'react-bootstrap';

function ContactPage() {

    // email address
    const [emailAddress, setEmailAddress] = useState("");

    // custom form submit logic
    function revealAddress() {
        const first = "johannes";
        const domain = "steinsbo";
        const tld = ".net";
        setEmailAddress(first + "@" + domain + tld);
    };

    return (
        <div className="contact">
            <div className="contact__content">
                <Container className="pb-4">
                    <Row>
                        <Col xs="12">
                            <h1 className="contact__title">Lets get in touch!</h1>
                            {!emailAddress && <p className="contact__reveal" onClick={revealAddress}>Click here to reveal email</p>}
                            {emailAddress && <p className="contact__address">{emailAddress}</p>}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default ContactPage;
