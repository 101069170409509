import React from 'react';
import 'scss/ResumeTimeline.scss';
import { Timeline, Event } from "react-timeline-scribble";
import data from 'experiences.json';


function ResumeTimeline() {

    return (
        <Timeline>
            {
                data.experiences.map((experience, index) => 
                    <Event key={index} interval={experience.interval} title={experience.title} subtitle={experience.subtitle}>
                        {experience.description}
                    </Event>
                )
            }
        </Timeline>
    );
}

export default ResumeTimeline;
