import React from 'react';
import 'scss/Home.scss';
import { Container, Col, Row } from 'react-bootstrap';
import ProjectShowcaseList from '../components/ProjectShowcaseList';

function HomePage() {

    return (
        <div className="home">
            <div className="home__hero">
                <Container className="h-50 d-flex">
                    <Row className="my-auto">
                        <Col className="pt-5">
                            <h3 className="hero__greeting">Hi there.</h3>
                            <h1 className="hero__myname">I'm Johannes</h1>
                            <p className="hero__caption">.NET / Backend<span>Developer</span></p>
                            <a className="hero__scrolldown" href="#work">Check out my work</a>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="work" className="home__work">
                <Container>
                    <Row className="pb-5">
                        <Col xs="12">
                            <h2 className="work__title">Previous work</h2>
                        </Col>
                    </Row>
                    <ProjectShowcaseList />
                </Container>
            </div>
        </div>
    );
}

export default HomePage;
