import React from 'react';
import 'scss/Navigation.scss';
import logo from 'assets/logo.svg';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

function Navigation() {

    return (
        <Navbar expand="lg" fixed="top" variant="dark" style={{ background: "#271747"}}>
            <Navbar.Brand>
                <NavLink to="/">
                    <img src={logo} height="48" className="d-inline-block mt-2" alt="Website logo" />
                </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navigation">
                    <NavLink to="/" exact activeClassName="navigation__link--active" className="navigation__link">Home</NavLink>

                    <NavLink to="/resume" exact activeClassName="navigation__link--active" className="navigation__link">Resume</NavLink>

                    <NavLink to="/about" exact activeClassName="navigation__link--active" className="navigation__link">About</NavLink>

                    <NavLink to="/contact" exact activeClassName="navigation__link--active" className="navigation__link">Contact</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        /* data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E */
    );
}

export default Navigation;
