import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';
import Layout from './Layout.js';

function App() {
    return (
        <Layout />
    );
}

export default App;
