import React from 'react';
import 'scss/Resume.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ResumeTimeline from '../components/ResumeTimeline';

function ResumePage() {

    return (
        <div className="resume">
            <div className="resume__content">
                <Container>
                    <div className="resume__experiences pb-4">
                        <Row>
                            <Col xs="12">
                                <h1 className="experiences__title">Resume</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <a className="experiences__download" href="resume_js_2023.pdf">As PDF</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <h2 className="experiences__skills">Technical Skills</h2>
                                <Row>
                                    <Col xs="6">
                                        <p>C# / .NET</p>
                                    </Col>
                                    <Col xs="6">
                                        <p>Git / CICD</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <p>API / Integrations</p>
                                    </Col>
                                    <Col xs="6">
                                        <p>SQL</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <p>JavaScript</p>
                                    </Col>
                                    <Col xs="6">
                                        <p>Graphical Work</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <h2 className="experiences__skills">General Skills</h2>
                                <Row>
                                    <Col xs="6">
                                        <p>Communication</p>
                                    </Col>
                                    <Col xs="6">
                                        <p>Problem Solving</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <p>Teamwork</p>
                                    </Col>
                                    <Col xs="6">
                                        <p>Analytical</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <h1 className="experiences__experience">Work / Education</h1>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <ResumeTimeline />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default ResumePage;
