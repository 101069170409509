import React from 'react';
import 'scss/About.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AboutPage() {

    return (
        <div className="about">
            <div className="about__content">
                <Container className="content__information">
                    <Row className="justify-content-md-center">
                        <Col xs="12" lg="8" className="information__details">
                            <h1 className="information__title">About me</h1>
                            <p className="information__description">
                                Hello! My name is Johannes. I'm a software developer specializing in the .NET eco-system.
                                For almost as long as I can remember I've had a deep passion for coding, problem-solving and technology.
                                I'm currently having a blast working as a backend-developer at Norkon! :)
                            </p>
                            <p className="information__checkout">
                            <Link to="/contact" className="checkout__link">Feel free to contact me!</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default AboutPage;
