import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

function ProjectShowcase(props) {

    return (
        <Row className="pt-5 pb-5">

            {/* this column will change it's order depending on the numbering props being modulo 2 */}
            <Col xs="12" xl={{span: 6, order: (props.numbering % 2) ? 0 : 1, }}>
                <Image src={props.image} fluid/>
            </Col>

            {/* this column will change it's order depending on the numbering props being modulo 2 */}
            <Col xs="12" xl={{span: 6, order: (props.numbering % 2) ? 1 : 0, }}>
                <h2 className="work__header">{props.title}</h2>
                <p className="work__description">
                    {props.description}
                </p>
            </Col>
        </Row>
    );
}

export default ProjectShowcase;
