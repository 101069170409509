import React from 'react';
import ProjectShowcase from './ProjectShowcase.js';
import data from 'projects.json';

function ProjectShowcaseList() {

    return (
        data.projects.map((project, index) => 
            <div>
                <ProjectShowcase title={project.title} image={project.image}
                description={project.description} numbering={index} />
                {
                    /* put an <hr> tag after each project showcase,
                       but not on the last one. */
                    (index < data.projects.length - 1) ? <hr /> : null
                }
            </div>
        )
    );
}

export default ProjectShowcaseList;
