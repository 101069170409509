import React from 'react';
import Navigation from './components/Navigation.js';
import HomePage from './containers/HomePage.js';
import ResumePage from './containers/ResumePage.js';
import AboutPage from './containers/AboutPage.js';
import ContactPage from './containers/ContactPage.js';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

function Layout() {
    return (
        <Router>
            <div className="page">
            <Navigation />
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route path="/resume" component={ResumePage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contact" component={ContactPage} />
                </Switch>
            </div>
        </Router>
    );
}

export default Layout;
